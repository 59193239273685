import React from "react";
import Button from "components/Base/Button/Button";
import styles from './Modal.module.css';
import { useModalStore } from "providers/ModalStoreProvider";
import { setModal } from "../../store/modalStore/action";


const Modal = () => {
  const [modalStore, dispatch] = useModalStore()
  const handleClick = (e) => {
    e.stopPropagation()
    dispatch(setModal(''))
  }
    
    return <div className={styles["modal_container"]}>
    <div className={styles.modal_content}>
      <p>{modalStore.message}</p>
      <Button
        className={styles.wellBtn}
        disabled={false}
        value={modalStore.button}
        onClick={(e) => handleClick(e)}
      />
    </div>
  </div>
}

export default Modal