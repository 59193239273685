import { createContext, useContext, useReducer } from "react";
import { adminReducer, adminStoreInitialState } from "store/adminStore/reducer";


const AdminStoreContext = createContext([])

export const AdminStoreProvider = ({children}) => {
    const adminStore = useReducer(adminReducer, adminStoreInitialState)

    return (
        <AdminStoreContext.Provider value={adminStore}>
            {children}
        </AdminStoreContext.Provider>
    )
}


export const useAdminStore = () => useContext(AdminStoreContext)