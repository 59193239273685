import Rules from "pages/Rules/Rules";
import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";



const HomePage = lazy(() => import("pages/Home/Home"));
const MyDetails =lazy(() => import("pages/MyDetails/MyDetails"))
const Monetization = lazy(() => import("components/monetization/Monetization"));

export const DashboardRouter = () => {
  return (
    <>
      <Routes>
        <Route path="/home" element={<HomePage />} />
        <Route path="/monetization" element={<Monetization />} />
        <Route path="/myDetails" element={<MyDetails />} />
				<Route path="/rules" element={<Rules />} />
        <Route path="*" element={<Navigate to="home" />} />
      </Routes>
    </>
  );
};
