import React, { useEffect, useState } from "react";
import * as bookStoreActions from "../../../../store/bookStore/actions";
import styles from "./UpdateBook.module.css";
import { File as FileComp } from "../../createBook/File/File";
import { useBookStore } from "providers/BookStoreProvider";
import Button from "components/Base/Button/Button";
import { IoMdAddCircleOutline, IoMdClose } from "react-icons/io";
import instance from "utils/axios";
import SelectAuthor from "components/adminComponents/selectAuthor/SelectAuthor";
import { queryClient } from "index";
import { checkRole } from "utils/checkRole";
import { useModalStore } from "providers/ModalStoreProvider";
import { setModal } from "store/modalStore/action";
import { errorCatch } from "utils/errorCatch";

const UpdateBook = ({ setGenreShow, setIsUpdateMode, genres }) => {
  const [authorObj, setAuthorObj] = useState({});
  const [, setModalStore] = useModalStore();
  const isAdmin = checkRole();
  const [selectedGenres, setSelectGenres] = useState([]);
  const [img, setImg] = useState(null);
  const [imgV, setImgV] = useState(null);
  const [bookStore, dispatch] = useBookStore();
  const [bookGenre, setBookGenre] = useState([]);
  const [confirm, setConfirm] = useState(false);

  useEffect(() => {
    setBookGenre(bookStore.genres);
  }, [bookStore.genres]);

  useEffect(() => {
    setImg(bookStore.img);
    if (bookStore.authorId)
      setAuthorObj({
        authorName: bookStore.authorName,
        id: bookStore.authorId,
      });
  }, [bookStore.authorId, bookStore.authorName, bookStore.img, dispatch]);

  const chaptersList = bookStore?.chapters
    ? Object.keys(bookStore.chapters).map((chapterId) => ({
        ...bookStore.chapters[chapterId],
        id: bookStore.chapters[chapterId].id,
      }))
    : [];

  useEffect(() => {
    const newGenres =
      bookGenre && genres
        ? bookGenre
            .map((id) => {
              const foundGenre = genres.find((genre) => genre.id === id);
              return foundGenre || null;
            })
            .filter((e) => e !== null)
        : [];
    setSelectGenres(newGenres);
  }, [bookGenre, genres]);

  useEffect(() => {
    if (bookStore.img instanceof File) {
      setImg(URL.createObjectURL(bookStore.img));
    }
    if (bookStore.imgVisible) {
      setImgV(bookStore.imgVisible);
    }
  }, [bookStore.img, bookStore.imgVisible]);

  const showGenreHandle = () => {
    setGenreShow(true);
  };
  const addChapterHandle = (value) => {
    dispatch(bookStoreActions.addChapter(value));
  };
  const cancel = () => {
    dispatch(bookStoreActions.deleteData());
    setIsUpdateMode(false);
  };
  const submitHandle = async (e) => {
    e.preventDefault();
    setConfirm(false);
    try {
      const formData = new FormData();
      const { chapterNames, chapters, chapterNumbers, chaptersId } =
        chaptersList?.reduce(
          (res, obj) => {
            res.chapterNames.push(obj.name ? obj.name : null);
            res.chapters.push(obj.file);
            res.chapterNumbers.push(chaptersList?.indexOf(obj));
            res.chaptersId.push(obj.id);
            return res;
          },
          {
            chapterNames: [],
            chapters: [],
            chapterNumbers: [],
            chaptersId: [],
          },
        );
      if (!bookStore.img) {
        setModalStore(setModal("Загрузите обложку книги!", "Редактировать"));
        return;
      }
      if (!bookStore.name) {
        setModalStore(setModal("Введите название книги!", "Редактировать"));
        return;
      }
      if (!bookStore.description) {
        setModalStore(setModal("Введите описание книги!", "Редактировать"));
        return;
      }
      if (!bookStore.genres.length) {
        setModalStore(setModal("Выберите жанр книги!", "Редактировать"));
        return;
      }
      if (chapterNames?.includes(null)) {
        setModalStore(
          setModal("Введите название каждой главы!", "Редактировать"),
        );
        return;
      }
      if (!chaptersList.length) {
        setModalStore(setModal("Добавьте главу книги!", "Редактировать"));
        return;
      }
      formData.append("img", bookStore.img);
      formData.append("name", bookStore.name);
      formData.append("description", bookStore.description);
      formData.append("genres", JSON.stringify(selectedGenres));
      formData.append("chapterNames", JSON.stringify(chapterNames));
      chapters.forEach((chapter) => formData.append("chapter", chapter));
      formData.append("chapterNumbers", JSON.stringify(chapterNumbers));
      formData.append("chaptersId", JSON.stringify(Object.values(chaptersId)));
      formData.append("bookId", bookStore.id);
      formData.append("authorId", authorObj?.id);
      formData.append("authorName", authorObj?.authorName);
      formData.append("restriction", bookStore.restriction);

      if (chapterNames.length === chaptersList.length) {
        const { data } = await instance.put(`/book`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        queryClient.invalidateQueries({ queryKey: ["book"] });
        dispatch(bookStoreActions.deleteData());
        setModalStore(setModal("Книга опубликована!", "ОК"));
        setIsUpdateMode(false);
        return data;
      }
    } catch (e) {
      setModalStore(setModal(errorCatch(e)), "OK");
    }
  };

  return (
    <div className={styles.mask}>
      <div
        className={styles.createBook_main}
        onClick={(e) => e.stopPropagation()}
      >
        {confirm && (
          <div className={styles.confirm}>
            <div className={styles.confirm_content}>
              <IoMdClose color="#B4B4B4" onClick={() => setConfirm(!confirm)} />
              <p>
                Данная книга находится в архиве, после обновления она вновь
                будет опубликована
              </p>
              <div>
                <button
                  className={styles.confirm_button_cancel}
                  onClick={() => setConfirm(!confirm)}
                >
                  Отмена
                </button>
                <button
                  className={styles.confirm_button}
                  onClick={(e) => submitHandle(e)}
                >
                  Восстановить
                </button>
              </div>
            </div>
          </div>
        )}
        <IoMdClose className={styles.close} onClick={cancel} />
        <h1>Обновление книги</h1>
        <div className={styles.createBook_form}>
          <label htmlFor="img_file">
            <div className={styles.divInp_file}>
              {imgV || img ? (
                <img src={imgV || img} alt="" />
              ) : (
                <div className={styles.noneImg}>
                  <IoMdAddCircleOutline />
                  <p>Обложка (не более 2 мб)</p>
                </div>
              )}
              <input
                type="file"
                id="img_file"
                className={styles.inp}
                accept="image/*"
                onChange={(e) => {
                  if (!e.target.files[0]) {
                    return;
                  }
                  dispatch(
                    bookStoreActions.changeField("img", e.target.files[0]),
                  );
                  let reader = new FileReader();
                  reader.onload = (ev) => {
                    setImgV(null);
                    setImg(ev.target.result);
                  };
                  reader.readAsDataURL(e.target.files[0]);
                }}
              />
            </div>
          </label>
          <div className={styles.divInp_form}>
            <input
              type="text"
              className={styles.divForm_inp}
              value={bookStore.name}
              onChange={(e) => {
                dispatch(bookStoreActions.changeField("name", e.target.value));
              }}
              maxLength={100}
              placeholder="Название книги*"
            />
            {isAdmin ? (
              <SelectAuthor setAuthor={setAuthorObj} author={authorObj} />
            ) : (
              <input
                type="text"
                className={styles.divForm_inp}
                value={bookStore.authorName}
                disabled
                placeholder="Автор*"
              />
            )}

            <textarea
              cols="30"
              rows="10"
              className={`${styles.divForm_inp} ${styles.inp_all}`}
              value={bookStore.description}
              onChange={(e) =>
                dispatch(
                  bookStoreActions.changeField("description", e.target.value),
                )
              }
              placeholder="Содержание (не более 1000 символов)"
              maxLength={1000}
            ></textarea>
            <div className={styles.div_limitation}>
              <input
                type="checkbox"
                onChange={() =>
                  dispatch(
                    bookStoreActions.changeField(
                      "restriction",
                      !bookStore.restriction,
                    ),
                  )
                }
                checked={bookStore.restriction}
              />
              <span>Органичение 18+</span>
            </div>
          </div>
        </div>
        <div className={styles.center}>
          <div className={styles.div_genres}>
            {bookStore.genres.length === 0 ? (
              <button type="button" onClick={showGenreHandle}>
                + Добавить жанры (не более 3)
              </button>
            ) : (
              <>
                {selectedGenres?.map((e) => {
                  return (
                    <button
                      key={e.id}
                      onClick={() =>
                        dispatch(bookStoreActions.toggleGenre(e.id))
                      }
                      className={styles.active}
                    >
                      {e.value}
                    </button>
                  );
                })}
                <IoMdClose
                  className={styles.delete_all_genres}
                  id="delete_all_genres"
                  onClick={() => dispatch(bookStoreActions.toggleGenre())}
                />
                {bookStore.genres.length < 3 ? (
                  <button onClick={showGenreHandle}>+ Добавить жанр</button>
                ) : null}
              </>
            )}
          </div>
          <div className={styles.div_formatBook}>
            <FileComp />
          </div>
          <button
            className={styles.addChapter}
            onClick={() => addChapterHandle("")}
            type="button"
          >
            + Добавить главу
          </button>

          <div className={styles.div_save}>
            {bookStore.status === "published" ? (
              <Button
                onClick={(e) => submitHandle(e)}
                value={"Обновить книгу"}
              />
            ) : (
              <Button
                onClick={() => setConfirm(true)}
                value={"Опубликовать книгу"}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default UpdateBook;
