import React, { useState } from "react";
import styles from "./Book.module.css";
import { HiOutlinePencil } from "react-icons/hi";
import Genres from "./genres/Genres";
import { FaTrashCan } from "react-icons/fa6";
import { bookService } from "service/Book.service";
import { loadFile } from "api";
import instance from "utils/axios";
import { useBookStore } from "providers/BookStoreProvider";
import { uploadsUrl } from "config";
import { editStart } from "store/bookStore";
import { queryClient } from "index";
import UpdateBook from "./UpdateBook/UpdateBook";
import { useModalStore } from "providers/ModalStoreProvider";
import { setModal } from "store/modalStore/action";
import { errorCatch } from "utils/errorCatch";
import { IoMdClose } from "react-icons/io";
const Book = ({
  id,
  count,
  img,
  name,
  authorName,
  genres,
  status,
  allGenres,
  restriction,
  setGenreShow,
  setGenres,
  showCount,
  updatedAt,
}) => {
  const [, setModalStore] = useModalStore();
  const [confirm, setConfirm] = useState(false);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const date = new Date(updatedAt);
  date.setDate(date.getDate() + 30);
  const handleClickDelete = async () => {
    try {
      await bookService.deleteBook(id);
      queryClient.invalidateQueries({
        queryKey: ["book"],
      });
      setConfirm(!confirm);
      setModalStore(setModal(`Книга ${name} перемещена в архив!`, "OK"));
    } catch (error) {
      setModalStore(setModal(errorCatch(error), "OK"));
    }
    return;
  };

  const [, dispatch] = useBookStore();
  const editShow = (e, bookId) => {
    e.stopPropagation();
    (async () => {
      try {
        const { data: book } = await instance.get(`/book/${bookId}`);
        const finalChapters = await new Promise(async (resolve, reject) => {
          try {
            const chaptersWithFiles = [];
            for (const { dataUrl, ...chapter } of book.chapters) {
              try {
                if (dataUrl) {
                  const file = await loadFile(dataUrl);
                  chaptersWithFiles.push({ ...chapter, file });
                  if (chaptersWithFiles.length === book.chapters.length) {
                    resolve(chaptersWithFiles);
                  }
                } else {
                  reject("Missing dataUrl");
                }
              } catch (error) {
                reject(error);
              }
            }
          } catch (error) {
            reject(error);
          }
        });
        const img = await loadFile(book.img);
        const imgVisible = `${uploadsUrl}${book.img}`;
        dispatch(
          editStart({
            ...book,
            chapters: finalChapters,
            img,
            authorName: book.authorName,
            authorId: book.authorId,
            imgVisible: imgVisible,
            genres: book.genres.map((item) => item.id),
          }),
        );
        setIsUpdateMode(!isUpdateMode);
      } catch (error) {
        setModalStore(setModal("Не удалось обновить книгу", "OK"));
      }
    })();
  };
  return (
    <>
      {confirm && (
        <div className={styles.confirm}>
          <div className={styles.confirm_content}>
            <IoMdClose color="#B4B4B4" onClick={() => setConfirm(!confirm)} />
            <p>
              Вы уверены, что хотите переместить книгу в архив? Она станет
              недоступна для чтения и будет удалена по истечении срока хранения
            </p>
            <div>
              <button
                className={styles.confirm_button_cancel}
                onClick={() => setConfirm(!confirm)}
              >
                Отмена
              </button>
              <button
                className={styles.confirm_button}
                onClick={() => handleClickDelete(id)}
              >
                Выполнить
              </button>
            </div>
          </div>
        </div>
      )}
      {isUpdateMode && (
        <UpdateBook
          id={id}
          genres={allGenres}
          setGenres={setGenres}
          setGenreShow={setGenreShow}
          setIsUpdateMode={() => setIsUpdateMode(!isUpdateMode)}
        />
      )}
      <div className={styles.wrapperBook}>
        <div className={styles.count}>{count}</div>
        <div className={styles.first}>
          <div
            className={styles.img}
            style={{ backgroundImage: `url(${img})` }}
          />
        </div>
        <div className={styles.name}>
          <p className={styles.title}>{name}</p>
        </div>
        <div className={styles.authorName}>{authorName}</div>
        <div className={styles.genres}>
          {genres?.map((item) => (
            <Genres genre={item.value} key={item.id} />
          ))}
          {restriction && <div className={styles.restriction}>18+</div>}
        </div>
        <div className={styles.items}>
          <div>{showCount}</div>
          <div>500</div>
          <div>2500</div>
          <div>3000</div>
        </div>

        <div className={styles.div_status}>
          {status === "published" ? (
            <div className={`${styles.status} ${styles.activeStatus}`}>
              <div className={styles.statusIcon}>
                <p>Опубликовано</p>
              </div>
            </div>
          ) : (
            <div className={`${styles.status} ${styles.reject}`}>
              <div className={styles.statusIcon}>
                <p>В архиве</p>
              </div>
              <p className={styles.data}>
                {" "}
                до {date.getDate()}.
                {(date.getMonth() + 1).toString().padStart(2, "0")}.
                {date.getFullYear()}
              </p>
            </div>
          )}
          <div className={styles.end}>
            <HiOutlinePencil onClick={(event) => editShow(event, id)} />
            {status === "published" && (
              <FaTrashCan onClick={() => setConfirm(!confirm)} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Book;
