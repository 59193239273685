import React, { useEffect, useState } from "react";
import * as bookStoreActions from "../../../store/bookStore/actions";
import styles from "./createBook.module.css";
import { File as FileComp } from "./File/File";
import { useBookStore } from "providers/BookStoreProvider";
import Button from "components/Base/Button/Button";
import { IoMdAddCircleOutline, IoMdClose } from "react-icons/io";
import SelectAuthor from "../selectAuthor/SelectAuthor";
import instance from "utils/axios";
import { useAdminStore } from "providers/AdminStoreProvider";
import { useModalStore } from "providers/ModalStoreProvider";
import { setModal } from "../../../store/modalStore/action";
import { queryClient } from "index";
import { errorCatch } from "utils/errorCatch";
import { useNavigate } from "react-router-dom";

const CreateBook = ({ setGenreShow, state, genres }) => {
  const navigate = useNavigate();
  const [adminStore] = useAdminStore();
  const [authorObj, setAuthorObj] = useState(adminStore.authors);
  const [, setModalStore] = useModalStore();
  const [img, setImg] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [bookStore, dispatch] = useBookStore();
  const chaptersList = bookStore?.chapters
    ? Object.keys(bookStore.chapters).map((chapterId) => ({
        ...bookStore.chapters[chapterId],
        id: chapterId,
      }))
    : [];
  const [selectedGenres, setSelectGenres] = useState([]);
  useEffect(() => {
    const newGenres =
      bookStore.genres && genres
        ? bookStore.genres
            .map((id) => {
              const foundGenre = genres.find((genre) => genre.id === id);
              return foundGenre || null;
            })
            .filter((e) => e !== null)
        : [];

    setSelectGenres(newGenres);
  }, [bookStore.genres, genres]);

  const addChapterHandle = (value, number) => {
    dispatch(bookStoreActions.addChapter(value, number));
  };

  const submitHandle = async (e) => {
    e.preventDefault();
    try {
      const { chapterNames, chapters, chapterNumbers } = chaptersList.reduce(
        (res, obj) => {
          res.chapterNames.push(obj.name);
          res.chapters.push(obj.file);
          res.chapterNumbers.push(chaptersList.indexOf(obj));
          return res;
        },
        { chapterNames: [], chapters: [], chapterNumbers: [] }
      );
      if (!bookStore.img) {
        setModalStore(setModal("Загрузите обложку книги!", "Редактировать"));
        return;
      }
      if (!bookStore.name) {
        setModalStore(setModal("Введите название книги!", "Редактировать"));
        return;
      }
      if (!bookStore.description) {
        setModalStore(setModal("Введите описание книги!", "Редактировать"));
        return;
      }
      if (!selectedGenres.length) {
        setModalStore(setModal("Выберите жанр книги!", "Редактировать"));
        return;
      }
      if (!chaptersList.length) {
        setModalStore(setModal("Добавьте главу книги!", "Редактировать"));
        return;
      }
      if (chapterNames?.includes("")) {
        setModalStore(
          setModal("Введите название каждой главы!", "Редактировать")
        );
        return;
      }
      if (!selectedGenres.length) {
        setModalStore(setModal("Выберите жанр книги", "Редактировать"));
        return;
      }

      const formData = new FormData();
      formData.append("img", bookStore.img);
      formData.append("name", bookStore.name);
      formData.append("authorId", authorObj?.id);
      formData.append("authorName", authorObj?.authorName);
      chapters.forEach((chapter) => formData.append("chapter", chapter));
      formData.append("chapterNames", JSON.stringify(chapterNames));
      formData.append("chapterNumbers", JSON.stringify(chapterNumbers));
      formData.append("description", bookStore.description);
      formData.append("restriction", bookStore.restriction);
      formData.append("genres", JSON.stringify(selectedGenres));

      if (chapterNames.length === chaptersList.length) {
        const { data } = await instance.post(`/book`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        queryClient.invalidateQueries({ queryKey: ["book"] });
        dispatch(bookStoreActions.deleteData());
        setModalStore(setModal(`${data.message}`, "ОК"));
        navigate("/admin/home");
      }
    } catch (e) {
      setModalStore(setModal(errorCatch(e), "Редактировать"));
    }
  };

  const showGenreHandle = () => {
    setGenreShow(true);
  };

  useEffect(() => {
    if (bookStore.img instanceof File) {
      setImg(URL.createObjectURL(bookStore.img));
    }
  }, [bookStore.img]);
  return (
    <div
      className={styles.createBook_main}
      onClick={(e) => e.stopPropagation()}
    >
      <h1>Создание книги</h1>
      <div>
        <div className={styles.createBook_form}>
          <label htmlFor="img_file">
            <div className={styles.divInp_file}>
              {img ? (
                <img src={img} alt="" />
              ) : (
                <div className={styles.noneImg}>
                  <IoMdAddCircleOutline />
                  <p>Обложка (не более 2 мб)</p>
                </div>
              )}
              <input
                type="file"
                id="img_file"
                className={styles.inp}
                accept="image/*"
                onChange={(e) => {
                  if (!e.target.files[0]) {
                    return;
                  }
                  dispatch(
                    bookStoreActions.changeField("img", e.target.files[0])
                  );
                  let reader = new FileReader();
                  reader.onload = (ev) => {
                    setImg(ev.target.result);
                  };
                  reader.readAsDataURL(e.target.files[0]);
                }}
              />
            </div>
          </label>
          <div className={styles.divInp_form}>
            <input
              type="text"
              className={styles.divForm_inp}
              value={bookStore.name}
              onChange={(e) => {
                dispatch(bookStoreActions.changeField("name", e.target.value));
              }}
              placeholder="Название книги*"
              maxLength={100}
            />
            <SelectAuthor setAuthor={setAuthorObj} />
            <textarea
              cols="30"
              rows="10"
              className={`${styles.divForm_inp} ${styles.inp_all}`}
              value={bookStore.description}
              onChange={(e) =>
                dispatch(
                  bookStoreActions.changeField("description", e.target.value)
                )
              }
              placeholder="Содержание (не более 1000 символов)"
              maxLength={1000}
            ></textarea>
            <div className={styles.div_limitation}>
              <input
                type="checkbox"
                onChange={() =>
                  dispatch(
                    bookStoreActions.changeField(
                      "restriction",
                      !bookStore.restriction
                    )
                  )
                }
                checked={bookStore.restriction}
              />
              <span>Органичение 18+</span>
            </div>
          </div>
        </div>
        <div className={styles.center}>
          <div className={styles.div_genres}>
            {bookStore.genres.length === 0 ? (
              <button type="button" onClick={showGenreHandle}>
                + Добавить жанры (не более 3)
              </button>
            ) : (
              <>
                {selectedGenres.map((e) => {
                  return (
                    <button
                      onClick={() =>
                        dispatch(bookStoreActions.toggleGenre(e?.id))
                      }
                      className={styles.active}
                    >
                      {e.value}
                    </button>
                  );
                })}
                <IoMdClose
                  className={styles.delete_all_genres}
                  id="delete_all_genres"
                  onClick={() => dispatch(bookStoreActions.toggleGenre())}
                />
                {bookStore.genres.length < 3 ? (
                  <button onClick={showGenreHandle}>+ Добавить жанр</button>
                ) : null}
              </>
            )}
          </div>
          <div className={styles.div_formatBook}>
            {chaptersList.length > 0 ? <FileComp /> : ""}
          </div>
          <button
            className={styles.addChapter}
            onClick={() => addChapterHandle("", chaptersList.length)}
            type="button"
          >
            + Добавить главу
          </button>

          <div className={styles.div_save}>
            <div className={styles.bookRules}>
              <input
                type="checkbox"
                onChange={() => setIsValid((prev) => !prev)}
              />        
                <a href="/rules" target="_blank">
                Согласен с правилами загрузки книг
                </a>
            </div>
            <Button
              disabled={!isValid}
              type="submit"
              onClick={(e) => submitHandle(e)}
              value={"Сохранить"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreateBook;
