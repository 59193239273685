import React from "react";
import style from "./dataProcessing.module.css";

const DataProcessing = () => {
  return (
    <div className={style.container}>
      <h1>Положение об обработке персональных данных</h1>
      <h2>
        ГЛАВА 1 <br />
        ОБЩИЕ ПОЛОЖЕНИЯ
      </h2>
      <div>
        <p>1.1. Политика обработки персональных данных пользователь проекта Bookva
        (далее – Политика) разработана в соответствии с требованиями Закона
        Республики Беларусь №99-3 от 07.05.2021 «О защите персональных данных»
        (далее – Закон), а также иных нормативно-правовых актов Республики
        Беларусь в области персональных данных и определяет основные принципы и
        цели обработки персональных данных, категории субъектов и обрабатываемых
        персональных данных, права субъектов персональных данных, меры по
        обеспечению защиты персональных данных.</p>
        <p>1.2. Политика регулирует
        обработку персональных данных как с использованием средств
        автоматизации, так и без их использования, если при этом обеспечиваются
        поиск персональных данных и (или) доступ к ним по определенным критериям
        (картотеки, списки, базы данных, журналы и др.). </p> 
        <p>1.3. В настоящей
        Политике используются следующие основные термины и их определения:
        биометрические персональные данные – информация, характеризующая
        физиологические и биологические особенности человека, которая
        используется для его уникальной идентификации (отпечатки пальцев рук,
        ладоней, радужная оболочка глаза, характеристики лица и его изображение
        и другое); блокирование персональных данных – прекращение доступа к
        персональным данным без их удаления; генетические персональные данные –
        информация, относящаяся к наследуемым либо приобретенным генетическим
        характеристикам человека, которая содержит уникальные данные о его
        физиологии либо здоровье и может быть выявлена, в частности, при
        исследовании его биологического образца; обезличивание персональных
        данных – действия, в результате которых становится невозможным без
        использования дополнительной информации определить принадлежность
        персональных данных конкретному субъекту персональных данных; обработка
        персональных данных – любое действие или совокупность действий,
        совершаемые с персональными данными, включая сбор, систематизацию,
        хранение, изменение, использование, обезличивание, блокирование,
        распространение, предоставление, удаление персональных данных;
        общедоступные персональные данные – персональные данные,
        распространенные самим субъектом персональных данных либо с его согласия
        или распространенные в соответствии с требованиями законодательных
        актов; оператор – государственный орган, юридическое лицо Республики
        Беларусь, иная организация, физическое лицо, в том числе индивидуальный
        предприниматель (далее, если не определено иное, – физическое лицо),
        самостоятельно или совместно с иными указанными лицами организующие и
        (или) осуществляющие обработку персональных данных; персональные данные
        – любая информация, относящаяся к идентифицированному физическому лицу
        или физическому лицу, которое может быть идентифицировано;
        предоставление персональных данных – действия, направленные на
        ознакомление с персональными данными определенных лица или круга лиц;
        распространение персональных данных – действия, направленные на
        ознакомление с персональными данными неопределенного круга лиц;
        специальные персональные данные – персональные данные, касающиеся
        расовой либо национальной принадлежности, политических взглядов,
        членства в профессиональных союзах, религиозных или других убеждений,
        здоровья или половой жизни, привлечения к административной или уголовной
        ответственности, а также биометрические и генетические персональные
        данные; субъект персональных данных – физическое лицо, в отношении
        которого осуществляется обработка персональных данных; трансграничная
        передача персональных данных – передача персональных данных на
        территорию иностранного государства; удаление персональных данных –
        действия, в результате которых становится невозможным восстановить
        персональные данные в информационных ресурсах (системах), содержащих
        персональные данные, и (или) в результате которых уничтожаются
        материальные носители персональных данных; уполномоченное лицо –
        государственный орган, юридическое лицо Республики Беларусь, иная
        организация, физическое лицо, которые в соответствии с актом
        законодательства, решением государственного органа, являющегося
        оператором, либо на основании договора с оператором осуществляют
        обработку персональных данных от имени оператора или в его интересах;
        физическое лицо, которое может быть идентифицировано, – физическое лицо,
        которое может быть прямо или косвенно определено, в частности через
        фамилию, собственное имя, отчество, дату рождения, идентификационный
        номер либо через один или несколько признаков, характерных для его
        физической, психологической, умственной, экономической, культурной или
        социальной идентичности. </p>
        <p>1.4. Политика может быть изменена Оператором
        без уведомления субъектов персональных данных. Действующая редакция
        Политики постоянно доступна по ссылке.</p>
      </div>
      <h2> ГЛАВА 2 <br /> ПРИНЦИПЫ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ </h2>
      <div>
        <p>2.1. Bookva, являясь оператором персональных данных, при обработке
        персональных данных работников придерживается следующих принципов:
        обработка должна осуществляться на законных основаниях и носить
        прозрачный характер. Это достигается, в том числе, путем информирования
        субъекта персональных данных об обработке данных и его правах; обработка
        должна быть соразмерна заявленным целям и ограничиваться достижением
        конкретных, заранее заявленных законных целей; при обработке принимаются
        меры по обеспечению достоверности обрабатываемых персональных данных,
        при необходимости они обновляются; хранение персональных данных в форме,
        позволяющей идентифицировать субъекта персональных данных,
        осуществляется не дольше, чем этого требуют заявленные цели обработки
        персональных данных. После истечения установленных сроков хранения
        персональные данные удаляются или анонимизируются; персональные данные
        обрабатываются способом, обеспечивающим соответствующую безопасность
        данных, включая защиту от несанкционированной или незаконной обработки,
        а также от случайной потери, повреждения или уничтожения, с
        использованием соответствующих технических и организационных мер.</p>
      </div>
      <h2>
        ГЛАВА 3 <br/> ПЕРЕЧЕНЬ СУБЪЕКТОВ, ПЕРСОНАЛЬНЫЕ ДАННЫЕ КОТОРЫХ ОБРАБАТЫВАЮТСЯ
        BOOKVA
      </h2>
      <div>
        <p>3.1. В Bookva обрабатываются персональные данные следующих категорий
        субъектов: 
            <p>• посетители веб-сайтов Оператора;</p> 
            <p>• пользователи приложений
        Оператора;</p> 
        <p>• работники (в том числе, бывшие) оператора и их
        родственники;</p> 
        <p>• кандидаты для приема на работу; • потенциальные,
        действующие и бывшие клиенты Оператора;</p>
        <p>• представители потенциальных,
        действующих и бывших клиентов Оператора; </p>
        <p>• студенты, учащиеся,
        проходящие (проходившие) у Оператора ознакомительную или
        производственную практику;</p>
        <p>• физические лица, относящиеся к
        аффилированным лицам, инсайдерам, взаимозависимым лицам Оператора;</p>  
        <p>•
        потенциальные, действующие и бывшие контрагенты Оператора, их
        представители;</p>
        <p>• лица, направляющие (направившие) Оператору обращения, а
        также заявления, ходатайства, иные документы, не являющиеся обращениями;</p> 
        <p>• посетители офисов Оператора;</p>
        </p>
      </div>
      <h2>
        ГЛАВА 4  <br/> КАТЕГОРИИ ОБРАБАТЫВАЕМЫХ ПЕРСОНАЛЬНЫХ ДАННЫХ, ЦЕЛИ, ПРАВОВЫЕ
        ОСНОВАНИЯ И СРОКИ ОБРАБОТКИ
      </h2>
      <div>
      <p>4.1. Обработка персональных данных осуществляется Оператором в различных
        процессах. Перечень обрабатываемых данных, правовые основания, срок
        обработки зависят от цели обработки персональных данных и категории
        субъекта персональных данных. Детальная информация с соотношением
        перечня обрабатываемых персональных данных, целей обработки, правовых
        оснований и сроков обработки в разрезе категорий субъектов персональных
        данных приведена в приложениях к настоящей политике: 
        <p>• Приложение 1 –
        порядок обработки персональных данных в связи с трудовой (служебной)
        деятельностью;</p> 
        <p>• Приложение 2 – порядок обработки персональных данных
        клиентов, их представителей; </p>
        <p>• Приложение 3 – порядок обработки
        персональных данных иных лиц.</p> 
        </p> 
        <p>4.2. Обработка специальных персональных
        данных, касающихся расовой либо национальной принадлежности,
        политических взглядов, членства в профессиональных союзах, религиозных
        или других убеждений, здоровья или интимной жизни, привлечения к
        административной или уголовной ответственности, а также биометрических и
        генетических персональных данных Оператором не осуществляется.</p>
      </div>
      <h2>ГЛАВА 5 <br/> ПРАВА СУБЪЕКТОВ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
      <div>
      <p>5.1. Субъекты персональных данных имеют право на: 
        <p>• в любое время без</p>
        объяснения причин отозвать согласие на обработку персональных данных; 
        <p>•
        на получение от Оператора информации, касающейся обработки своих
        персональных данных, и внесение изменений в свои персональные данные в
        случае, если персональные данные являются неполными, устаревшими или
        неточными;</p> 
        <p>• получать от Оператора информацию о предоставлении своих
        персональных данных третьим лицам;</p> 
        <p>• требовать от Оператора прекращения
        обработки своих персональных данных, включая их удаление, при отсутствии
        оснований для обработки персональных данных.</p>
    </p> 
    <p>5.2. Для реализацииперечисленных прав субъекту персональных данных необходимо подать
        Оператору заявление в письменной форме либо в виде электронного
        документа. Заявление должно содержать:
        <p>• фамилию, собственное имя,
        отчество (при наличии) субъекта персональных данных, адрес его места
        жительства (места пребывания); </p> 
        <p>• дату рождения субъекта персональных
        данных;</p> 
        <p>• идентификационный номер субъекта персональных данных, при
        отсутствии такого номера - номер документа, удостоверяющего личность
        субъекта персональных данных;</p> 
        <p>• изложение сути требований субъекта
        персональных данных;</p> 
        <p>• личную подпись либо в случае подачи электронного
        заявления, ввести в специальное поле смс-код, предоставляемый
        оператором. Заявления, не соответствующие описанным выше требованиям, к
        рассмотрению не принимаются. Ответ на заявление направляется субъекту
        персональных данных в форме, соответствующей форме подачи заявления,
        если в самом заявлении не указано иное.</p>
    </p>
    <p>5.3. За содействием в реализации
        своих прав субъект персональных данных в электронном виде посредством
        обращения через платформу Bookva.</p> 
    <p>5.4. Субъект персональных данных может
        обжаловать действия (бездействие) и решения Оператора, нарушающие его
        права при обработке персональных данных, в уполномоченный орган по
        защите прав субъектов персональных данных в порядке, установленном
        законодательством об обращениях граждан и юридических лиц.</p> 
      </div>
      
    <h2>ГЛАВА 6 <br/> Меры по обеспечению защиты персональных данных</h2>
    <div>
    <p>6.1. Оператором приняты все обязательные меры по обеспечению защиты персональных данных, в том числе:
      <p>• назначено ответственное лицо за осуществление внутреннего контроля за обработкой персональных данных;</p>
      <p>•  утверждены документы, определяющие политику в отношении обработки персональных данных;</p>
      <p>•  работники и иные лица, непосредственно осуществляющие обработку персональных данных, ознакомлены с положениями законодательства о персональных данных, в том числе с требованиями по защите персональных данных, документами, определяющими политику Оператора в отношении обработки персональных данных; </p>
      <p>•  обучение работников и иных лиц осуществляется в порядке, установленном законодательством;</p>
      <p>• определен порядок доступа к персональным данным, в том числе обрабатываемым в информационном ресурсе (системе);</p>
      <p>•  осуществляется техническая и криптографическая защита персональных данных.</p>
    </p>
    </div>
    
    <h2>ГЛАВА 7 <br/>ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ ПО ПОРУЧЕНИЮ ОПЕРАТОРУ, ТРАНСГРАНИЧНАЯ ПЕРЕДАЧА ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
    <div>
      <p>7.1. Обработка персональных данных следующих категорий субъектов персональных данных осуществляется с привлечением уполномоченных лиц:
        <p>• кандидаты для приема на работу к Оператору;</p>
        <p>• потенциальные и действующие клиенты Оператора, их представители.</p>
      </p>
      <p>7.2. Актуальный перечень целей обработки и обрабатываемых персональных данных, приведены в приложении 4 к настоящей политике и может быть предоставлен для ознакомления в офисах и на интернет-сайте Оператора.</p>
      <p>7.3. Привлекая к обработке уполномоченных лиц, Оператор придерживается следующих требований:
        <p>• к обработке могут привлекаться исключительно уполномоченные лица, обеспечивающие надлежащие гарантии применения соответствующих технических и организационных мер таким способом, чтобы обработка отвечала требованиям законодательства Республики Беларусь по защите персональных данных и обеспечивала защиту прав субъекта персональных данных;</p>
        <p>• обработка регулируется заключенным с уполномоченным лицом договором.</p></p>
      <p>7.4. Уполномоченное лицо и любое лицо, действующее под его контролем, имеющее доступ к персональным данным, не должны обрабатывать эти данные, кроме как по инструкциям от оператора.</p>
      <p>7.5. Если для обработки персональных данных по поручению оператора необходимо получение согласия субъекта персональных данных, такое согласие получает оператор. </p>
      <p>7.6. В случае, если оператор поручает обработку персональных данных уполномоченному лицу, ответственность перед субъектом персональных данных за действия указанного лица несет оператор. Уполномоченное лицо несет ответственность перед оператором.</p>
      <p>7.7. Трансграничная передача персональных данных может осуществляется Оператором в адрес контрагентов Оператора (в любой стране) – в целях совершения действий, установленных заключенным с контрагентом Оператора или субъектом персональных данных договором, в случаях, когда обработка персональных данных является необходимой для выполнения обязанностей (полномочий), предусмотренных законодательными актами. </p>
</div>
      <h2>ГЛАВА 8 <br/>
КОНТРОЛЬ ЗА СОБЛЮДЕНИЕМ ЗАКОНОДАТЕЛЬСТВА РЕСПУБЛИКИ БЕЛАРУСЬ И ЛОКАЛЬНЫХ ПРАВОВЫХ АКТОВ BOOKVA В ОБЛАСТИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
      <div>
        <p>8.1. Оператором назначается ответственное лицо за осуществление внутреннего контроля за обработкой персональных данных, в функции которого входят, в том числе:
          <p>•  осуществление контроля за соблюдением принципов обработки персональных данных;</p>
          <p>• участие в установленном порядке во всех инициативах, которые связаны с обработкой персональных данных;</p>
          <p>•  информирование и консультирование сотрудников Оператора о порядке обработки персональных данных;</p>
          <p>• участие в процессе определения мер технической и криптографической защиты персональных данных;</p>
          <p>• участие в процессе рассмотрения обращений субъектов персональных данных, связанных с реализацией их прав;</p>
          <p>• взаимодействие с уполномоченным органом по защите прав субъектов персональных                   данных – Национальным центром защиты персональных данных Республики Беларусь, а также с иными государственными органами по вопросам защиты персональных данных.</p>
        </p>
</div>
    </div>
  );
};

export default DataProcessing;
