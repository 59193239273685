import React, { useState, useCallback, useEffect } from "react";
import styles from "./Sum.module.css";
import { addDays, format, subDays } from "date-fns";
import { ru } from "date-fns/locale";
import {DateRangePicker} from "components/adminComponents/calendar/DateRangePicker";
import { useQuery } from "@tanstack/react-query";
import { bookService } from "service/Book.service";

const Sum = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [books, setBooks] = useState()
  const { data: BooksData } = useQuery({
    queryKey: ["book"],
    queryFn: () => bookService.getBook(),
  });
  useEffect(() => {
    if(BooksData){
      setBooks(BooksData[0].books)
    }
  },[BooksData])
  const adwCount = books?.reduce((acc, item) => acc + item.showCountAds, 0)
    
	const [valueDateRangePicker, setValueDateRangePicker] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);
  const formattedValueDateRangePickerStartDate = valueDateRangePicker?.[0].startDate
    ? format(valueDateRangePicker[0].startDate, "dd.MM.yyyy", { locale: ru })
    : "";
  const formattedValueDateRangePickerEndDate = valueDateRangePicker?.[0].endDate
    ? format(valueDateRangePicker[0].endDate, "dd.MM.yyyy", { locale: ru })
    : "";

  const handleChangeValueDateRangePicker = useCallback((ranges) => {
    const { selection } = ranges;
    setValueDateRangePicker([selection]);
  }, []);

  return (
    <div className={styles.sum_main}>
      <div className={styles.header}>
				   <div className={styles.centerCalendar}>
          <div className={styles.range} onClick={() => setIsOpen(!isOpen)}>
            <p>{formattedValueDateRangePickerStartDate === formattedValueDateRangePickerEndDate ? "За весь период " : `${formattedValueDateRangePickerStartDate} - ${formattedValueDateRangePickerEndDate}`}</p>
          </div>
          {isOpen ? 
          <DateRangePicker
          open = {isOpen}
          close = {setIsOpen}
          editableDateInputs={true}
          locale={ru}
          onChange={handleChangeValueDateRangePicker}
          ranges={valueDateRangePicker}
        /> 
        : "" }
          
        </div>
      </div>
      <div className={styles.count}>
        <span>Общее количество показов рекламы:</span>
        <p>{ adwCount}</p>
      </div>
      <div className={styles.count}>
        <span>Ваш заработок:</span>
        <p>{adwCount * 555}</p>
      </div>
      <button className={styles.sum_button}>Вывести</button>
    </div>
  );
};

export default Sum;
