import { createContext, useContext, useReducer } from "react";
import { modalReducer, modalInitialState } from "store/modalStore/reducer";

const ModalStoreContext = createContext([])

export const ModalStoreProvider = ({children}) => {
    const modalStore = useReducer(modalReducer, modalInitialState)

    return (
        <ModalStoreContext.Provider value={modalStore}>
            {children}
        </ModalStoreContext.Provider>
    )
}

export const useModalStore = () => useContext(ModalStoreContext)