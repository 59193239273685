import axios from "axios";
import { baseUrl } from "config";
import React, { useEffect, useState } from "react";
import styles from "./Genre.module.css";
import { SearchIcon } from "assets/icons/searchIcon";
import { useBookStore } from "providers/BookStoreProvider";
import { toggleGenre } from "store/bookStore";
import { IoMdClose } from "react-icons/io";
import { useModalStore } from "providers/ModalStoreProvider";
import { setModal } from "../../store/modalStore/action";

const Genre = ({  setGenreShow, genres, setGenres }) => {
  const [search, setSearch] = useState("");
  const [bookStore, dispatch] = useBookStore();
  const [, setModalStore] = useModalStore()

  const showGenreHandle = (e) => {
    e.stopPropagation();
    setGenreShow(false);
  };

  const addGenre = (id) => {
    dispatch(toggleGenre(id))
  }
  const deleteGenre = (event, id) => {
    event.stopPropagation()
    dispatch(toggleGenre(id))
  }

  useEffect(() => {
    (async () => {
      const res = await axios.get(`${baseUrl}/genres`);
      setGenres(res.data);
    })();
  }, [search, setGenres]);
  return (
    <div className={styles.genre__back}>
      <div className={styles.div_genre}>
        <IoMdClose
          className={styles.close}
          onClick={() => {
						setGenreShow(false)
					}}
        />
        <div className={styles.div_inp}>
          <h1>Добавить жанры (не более 3)</h1>
          <div className={styles.search}>
            <input
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              id="search"
            />
            {!search ? (
              <label htmlFor="search" className={styles.searchLabel}>
                <SearchIcon /> Поиск
              </label>
            ) : null}
          </div>
        </div>
        <div className={styles.genre_div}>
          {genres ? (
            <>
              {genres?.map((e) => {
                return (
                  <button
                    onClick={ bookStore.genres.length === 3 ? ()=> setModalStore(setModal("Можно выбрать не более 3 жанров!", "Редактировать")) : () => addGenre(e.id) }
                    key={e.id}
                    className={`${styles.but_genre} ${
                      bookStore.genres.some((genre) => genre === e.id)
                        ? styles.active
                        : ""
                    }`}
                  >
                   <p>{e.value}</p> <IoMdClose onClick={(event)=>deleteGenre(event,e.id)}/>
                  </button>
                );
              })}
            </>
          ) : (
            <></>
          )}
        </div>
        <div className={styles.div_button}>
          <button
            className={`${styles.genre_but} ${
              bookStore.genres.length ? styles.orange : ""
            }`}
            onClick={showGenreHandle}
          >
            Продолжить
          </button>
        </div>
      </div>
    </div>
  );
};
export default Genre;