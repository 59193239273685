import React, { useEffect, useState } from "react";
import styles from "./AdminHome.module.css";
import Layout from "components/layout/Layout";
import Input from "components/Base/Input/Input";
import { GoPeople } from "react-icons/go";
import { PiBooksLight } from "react-icons/pi";
import Sorted from "components/adminComponents/Sorted/Sorted";

import Description from "components/adminComponents/description/Description";
import { useQuery } from "@tanstack/react-query";
import {adminService} from "service/Admin.service";
import { useFilter } from "utils/useFilter";
import Pagination from "components/adminComponents/pagination/Pagination";
import FullAuthor from "components/adminComponents/author/FullAuthor";
import Header from "components/header/Header";
import FullBook from "components/adminComponents/book/FullBook";
import { useAdminStore } from "providers/AdminStoreProvider";
import { getAuthors } from "store/adminStore/actions";
import PaymentData from "components/adminComponents/paymentData/PaymentData";
const AdminHome = ({ pagesCount }) => {
  const [, setAdminStore] = useAdminStore()
  const [count, setCount] = useState(0);
  const map = [
    {
      icon: GoPeople,
      name: "Авторы",
    },
    {
      icon: PiBooksLight,
      name: "Книги",
    },
  ];
  useEffect(() => {
    if (pagesCount) setCount(pagesCount);
    else {
      setCount(0);
    }
  }, [pagesCount]);
  const [filter, setFilter] = useState("createdAt");
  const [isDesc, setIsDesc] = useState(false);
  const [search, setSearch] = useState("");
  const { data: author } = useQuery({
    queryKey: ["author"],
    queryFn: () => adminService.getAuthor(),
    refetchInterval: 10000,
  });
  const { data: book } = useQuery({
    queryKey: ["book"],
    queryFn: () => adminService.getAllBooks(),
    refetchInterval: 10000,
  });

  useEffect(()=>{
    if(author){
      setAdminStore(getAuthors(author))
    }
  }, [author, setAdminStore])
  const [pageCount, setPageCount] = useState(1);
  const showCountAdvertisement = book?.reduce((acc, book) => acc+book.showCountAds, 0)
  const { newBook, newAuthor } = useFilter(
    filter,
    search,
    book,
    author,
    isDesc
  );
  return (
    <Layout>
      <div className={styles.wrapper}>
        <Header />
        <header>
          <div className={styles.buttons}>
            {map.map((item, i) => (
              <div key={item.icon + 1}
                className={`${styles.item} ${count === i && styles.itemActive}`}
                onClick={() => setCount(i)}
              >
                <item.icon />
                <p>{item.name}</p>
              </div>
            ))}
          </div>
          <Input
            placeholder={"Поиск"}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className={styles.input}
          />
        </header>
        <PaymentData showCount={showCountAdvertisement}/>
        <main>
          <Sorted setFilter={setFilter} isDesc={isDesc} setIsDesc={setIsDesc} />
          <div className={styles.items}>
            {count ? (
              <>
                <Description />
                <>
                  {newBook ? (
                    <>
                      {newBook[pageCount - 1]?.map((item, i) => (
                        <FullBook
                          showCount ={item.showCount}
                          count={i + 1}
                          item={item}
                          id={item.id}
                          img={item.img}
                          name={item.name}
                          authorName={item.authorName}
                          genres={item.genres}
                          actionInfo={item.actionInfo}
                          key={i}
                          restriction={item.restriction}
                          status={item.status}
                          updatedAt ={item.updatedAt}
                        />
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              </>
            ) : (
              <>
                {newAuthor?.length ? (
                  <>
                    {newAuthor[pageCount - 1]?.map((item, i) => (
                      <FullAuthor
                        key={item.id}
                        {...item}
                        count={i + 1}
                        name={item.name}
                        surname={item.surname}
                        status = {item.actionInfo?.status}
                        email={item.email}
                      />
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </main>
        <div className={styles.right}>
          <Pagination
            totalPages={count ? newBook?.length : newAuthor?.length}
            currentPage={pageCount}
            onChangePage={setPageCount}
          />
        </div>
        {/* </div> */}
      </div>
    </Layout>
  );
};

export default AdminHome;
