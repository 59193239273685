import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import NavModalProvider from "providers/NavModalProvider";
import { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom/dist";
import { App } from "./App";
import { ModalStoreProvider } from "providers/ModalStoreProvider";

export const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <NavModalProvider>
        <ModalStoreProvider>
          <Suspense fallback={<span>Loading... </span>}>
            <App />
          </Suspense>
        </ModalStoreProvider>
      </NavModalProvider>
    </BrowserRouter>
  </QueryClientProvider>
);
