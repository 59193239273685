import axios from "axios";
import React, { useState, useContext } from "react";
import { baseUrl } from "../../config";
import Button from "../Base/Button/Button";
import Input from "../Base/Input/Input";
import style from "./editEmail.module.css";
import { useModalStore } from "providers/ModalStoreProvider";
import { setModal } from "../../store/modalStore/action";
import { CountDown } from "components/Timer/timer";
import { TokenContext } from "App";
import { errorCatch } from "utils/errorCatch";



const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export default function EditEmail({onclick, oldEmail, editEmail, updateEmail}) {
  const [sended, setSended] = useState(false);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [repeat, setRepeat] = useState(false)
  const [, setModalStore] = useModalStore()
  const { token } = useContext(TokenContext);


  const handleSendEmail = async () => {
    try {
      await axios.post(`${baseUrl}/password-reset/change-email`, {
        email
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setModalStore(setModal("Код отправлен", 'OK'))
      setTimeout(()=> {setRepeat(true)},900 * 1000 )
      setSended(true);
    } catch (e) {
      setModalStore(setModal(errorCatch(e)))
    }
  };
  
  const handleCheckCode = async () => {
    try {
      const { data } = await axios.post(`${baseUrl}/password-reset/confirm-email`, {
        newEmail: email,
        oldEmail,
        code,
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      onclick(!editEmail)
      setModalStore(setModal(data.message))
      updateEmail(email)
    } catch (e) {
      setModalStore(setModal(errorCatch(e)))
    }
  };

  return (
    <div className={style.edit__container}>
      <div className={style.edit}>
        <h1>Изменить адрес электронной почты</h1>
        <div className={style.reset}>
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type={"text"}
            placeholder={"E-mail"}
            readonly={false}
          />
          {!sended ? (
            <>
              <p className={style.edit__label}>
               Введите новый адрес электронной почты!
              </p>
              <Button
                disabled={email.match(emailRegex) ? false : true}
                onClick={handleSendEmail}
                value={"Продолжить"}
              />
              <Button
                onClick={onclick}
                value={"Отмена"}
              />
            </>
          ) : (
            <>
              <Input
                type={"text"}
                value={code.code}
                onChange={(e) => {
                  setCode(e.target.value);
                }}
                placeholder={"Проверочный код"}
              />
              <p className={style.edit__label}>
               Подтвердите адрес электронной почты. Мы отправили Вам проверочный код. Код
                действителен </p> {<CountDown/>}<p className={style.edit__label}> минут.
              </p>
              {repeat ? <span onClick={handleSendEmail} className="orange">
                Отправить код повторно
              </span> : ''}
              <Button
                // disabled={code.code.length == 6 ? false : true}
                onClick={handleCheckCode}
                value={"Продолжить"}
              />
              <Button
                onClick={() => onclick(!editEmail)}
                value={"Отмена"}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
