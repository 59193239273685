import * as Types from "./type";

export const toggleGenre = (genreId) => ({
  type: Types.TOGGLE_GENRE,
  payload: { genreId },
});

export const changeField = (field, value) => ({
  type: Types.CHANGE_FIELD,
  payload: { field, value },
});

export const addChapter = (value, number, file) => ({
  type: Types.ADD_CHAPTER,
  payload: { value, number, file },
});

export const moveChapter = (newChapters) => ({
  type: Types.MOVE_CHAPTERS,
  payload: { newChapters },
});

export const changeChapter = (id, field, value) => ({
  type: Types.CHANGE_CHAPTER,
  payload: { id, field, value },
});

export const createStart = () => ({
  type: Types.CREATE_START,
});

export const editStart = (book) => ({
  type: Types.EDIT_START,
  payload: { book },
});

export const deleteChapter = (chapterId) => ({
  type: Types.DELETE_CHAPTER,
  payload: { chapterId },
});

export const deleteData = () => ({
  type: Types.DELETE_DATA,
});
