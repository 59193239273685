import img from "assets/bookva-512x512.png";
import BookIcon from "assets/icons/BookIcon";
import {  NavLink,  useNavigate,} from "react-router-dom";
import { checkRole } from "utils/checkRole";

import styles from "../Navigation.module.css";
import AuthorIcon from "assets/icons/AuthorIcon";
import instance from "utils/axios";
import { useContext } from "react";
import { TokenContext } from "App";
import { IoMdClose } from "react-icons/io";
import { NavModalContext } from "providers/NavModalProvider";
import { useSize } from "utils/useSize";
import { motion, AnimatePresence } from "framer-motion";
import Books from "assets/icons/Books";
import MonetizationIcon from "assets/icons/Monetisation";
import Person from "assets/icons/Person";
const Nav = ({ type }) => {
  const { setToken } = useContext(TokenContext);
  const { setIsOpen, isOpen } = useContext(NavModalContext);
  const isAdmin = checkRole();
  const nav = useNavigate();
 
  const logout = () => {
    setToken("");
    localStorage.clear();
    nav("/login");
  };
  const onClickExcelAuthor = async () => {
    try {
      const { data } = await instance.post("/admin/excelTabsAuthor");
      return data;
    } catch (error) {}
  };
  const onClickExcelUser = async () => {
    try {
      const { data } = await instance.post("/admin/excelTabsUser");
      return data;
    } catch (error) {}
  };
  const { width } = useSize();
  const onClick = () => {
    setIsOpen(false);
  };
  return (
    <>
      {type ? (
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ x: "-360px" }}
              animate={{ x: 0 }}
              exit={{ x: "-360px" }}
              transition={{
                duration: 1,
              }}
              className={`${styles.div_navigate} ${type ? styles.fixed : ""}`}
            >
              <div className={styles.layout}>
                <div className={styles.navigate_logo}>
                  <img src={img} alt="" />
                  <p>BOOKVA</p>
                  {width <= 1100 ? (
                    <div
                      className={styles.close}
                      onClick={() => setIsOpen(false)}
                    >
                      <IoMdClose />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className={styles.navigate_menu}>
                  <ul className={styles.nav_ul} onClick={() => onClick()}>
                    <li className={styles.nav_li}>
                      <NavLink
                        to={isAdmin ? "/admin/home" : "/home"}
                        end
                        className={({ isActive }) =>
                          isActive ? styles["font-link"] : null
                        }
                      >
                        <Books />
                        {isAdmin ? "Книги" : "Мои книги"}
                      </NavLink>
                    </li>
                    {isAdmin ? (
                      <li className={styles.nav_li} onClick={() => onClick()}>
                      <NavLink
                      to={"/admin/"}
                      end
                      className={({ isActive }) =>
                        isActive ? styles["font-link"] : null
                      }
                    >
                          <Person />
                          Авторы
                      </NavLink>
                      </li>
                    ) : (
                      <li className={styles.nav_li} onClick={() => onClick()}>
                        <NavLink
                          to={"/monetization"}
                          className={({ isActive }) =>
                            isActive ? styles["font-link"] : null
                          }
                        >
                          <MonetizationIcon />
                          Монетизация
                        </NavLink>
                      </li>
                    )}
                    {isAdmin ? (
                      ""
                    ) : (
                      <li className={styles.nav_li} onClick={() => onClick()}>
                        <NavLink
                          to={"/myDetails"}
                          className={({ isActive }) =>
                            isActive ? styles["font-link"] : null
                          }
                        >
                          <Person />
                          Мои данные
                        </NavLink>
                      </li>
                    )}

                    {isAdmin ? (
                      <>
                        <li className={styles.nav_li} onClick={() => onClick()}>
                          <NavLink to={"/admin/genre"}>
                            <h2>Жанры</h2>
                          </NavLink>
                        </li>
                        <li>
                          <button
                            className={styles.button}
                            onClick={() => nav("/admin/add/author")}
                          >
                            <div className={styles.button__type}>
                              <div>
                                <AuthorIcon />
                              </div>
                              <div>Создать автора</div>
                            </div>
                          </button>
                        </li>
                        <li>
                          <button
                            className={styles.button}
                            onClick={() => nav("/admin/add")}
                          >
                            <div className={styles.button__type}>
                              <div>
                                <BookIcon />
                              </div>
                              <div>Создать книгу</div>
                            </div>
                          </button>
                        </li>
                        <li
                          className={styles.email__tabs}
                          onClick={() => onClickExcelAuthor()}
                        >
                          <p>Таблица на E-mail Авторы</p>
                        </li>
                        <li
                          className={styles.email__tabs}
                          onClick={() => onClickExcelUser()}
                        >
                          <p>Таблица на E-mail Пользователи</p>
                        </li>
                        <li className={styles.email__tabs}>
                          <NavLink to={"/rules"} target="_blank">
                            <p>Правила</p>
                          </NavLink>
                        </li>
                      </>
                    ) : (
                      <></>
                    )}
                  </ul>
                  <div className={styles.logout} onClick={() => logout()}>
                    Выйти
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      ) : (
        <div className={`${styles.div_navigate} ${type ? styles.fixed : ""}`}>
          <div className={styles.layout}>
            <div className={styles.navigate_logo}>
              <img src={img} alt="" />
              <p>BOOKVA</p>
              {width <= 1100 ? (
                <div className={styles.close} onClick={() => setIsOpen(false)}>
                  <IoMdClose />
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className={styles.navigate_menu}>
              <ul className={styles.nav_ul}>
                <li className={styles.nav_li}>
                  <NavLink
                    to={isAdmin ? "/admin/home" : "/home"}
                    className={({ isActive }) =>
                      isActive ? styles["font-link"] : null
                    }
                  >
                    <Books />
                    {isAdmin ? "Kниги" : "Мои книги"}
                  </NavLink>
                </li>
                {isAdmin ? (
                  <li className={styles.nav_li}>
                    <NavLink
                      to={"/admin/"}
                      end
                      className={({ isActive }) =>
                        isActive ? styles["font-link"] : null
                      }
                    >
                      <Person />
                      Авторы
                    </NavLink>
                  </li>
                ) : (
                  <li className={styles.nav_li}>
                    <NavLink
                      to={"/monetization"} // monetization
                      className={({ isActive }) =>
                        isActive ? styles["font-link"] : null
                      }
                    >
                      {" "}
                      <MonetizationIcon />
                      Монетизация
                    </NavLink>
                  </li>
                )}{" "}
                {isAdmin ? (
                  ""
                ) : (
                  <li className={styles.nav_li}>
                    <NavLink
                      to={"/myDetails"}
                      className={({ isActive }) =>
                        isActive ? styles["font-link"] : null
                      }
                    >
                      <Person />
                      Мои данные
                    </NavLink>
                  </li>
                )}
                {isAdmin ? (
                  <>
                    <li className={styles.nav_li}>
                      <a href={"/admin/genre"}>
                        <h2>Жанры</h2>
                      </a>
                    </li>
                    <li>
                      <NavLink to={"/admin/add/author"}>
                        <button className={styles.button}>
                          <div className={styles.button__type}>
                            <div>
                              <AuthorIcon />
                            </div>
                            <div>Создать автора</div>
                          </div>
                        </button>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/admin/add"}>
                        <button className={styles.button}>
                          <div className={styles.button__type}>
                            <div>
                              <BookIcon />
                            </div>
                            <div>Создать книгу</div>
                          </div>
                        </button>
                      </NavLink>
                    </li>
                    <li
                      className={styles.email__tabs}
                      onClick={() => onClickExcelAuthor()}
                    >
                      <p>Таблица на E-mail Авторы</p>
                    </li>
                    <li
                      className={styles.email__tabs}
                      onClick={() => onClickExcelUser()}
                    >
                      <p>Таблица на E-mail Пользователи</p>
                    </li>
                    <li className={styles.email__tabs}>
                      <NavLink to={"/rules"} target="_blank">
                        <p>Правила</p>
                      </NavLink>
                    </li>
                  </>
                ) : (
                  <></>
                )}
              </ul>
              <div className={styles.logout} onClick={() => logout()}>
                Выйти
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Nav;
