import React, { useEffect, useRef, useState } from "react";
import styles from "./PresonalDataAdmin.module.css";
import Input from "components/Base/Input/Input";
import Button from "components/Base/Button/Button";
import { useMutation, useQuery } from "@tanstack/react-query";
import { IoMdClose } from "react-icons/io";
import { authorService } from "service/Author.service";
import { adminService } from "service/Admin.service";
import { queryClient } from "index";
const PersonalDetailsAdmin = ({ id, setIsUpdateMode }) => {
  const [data, setData] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const { data: QueryData } = useQuery({
    queryKey: ["myData"],
    queryFn: () => authorService.getAuthorById(id),
  });

  useEffect(() => {
    if (!data.name || !data.surname || !data.alias) {
      setIsDisabled(true);
    } else setIsDisabled(false);
  }, [data.alias, data.name, data.surname]);

  const { mutate, data: MutateData } = useMutation({
    mutationFn: () =>
      adminService.setAuthorProfile(id, { ...data, paymentData }),
  });
  const modalRef = useRef(null);
  useEffect(() => {
    if (QueryData) {
      setData(QueryData);
      setPaymentData(
        QueryData.paymentData
          ? JSON.parse(QueryData.paymentData)
          : [
              {
                name: "Кошелек 1",
                number: "0",
              },
            ],
      );
    }
    if (MutateData) {
      setIsUpdateMode(false);
      queryClient.invalidateQueries({ queryKey: ["author"] });
    }
  }, [QueryData, MutateData, setIsUpdateMode]);
  const [paymentData, setPaymentData] = useState([]);

  return (
    <div className={styles.personalDetails} ref={modalRef}>
      <div className={styles.div_input}>
        <Input
          readonly={true}
          value={data.email}
          onChange={(e) => setData({ ...data, email: e.target.value })}
        />
        <Input
          value={data.name}
          placeholder={"Имя"}
          onChange={(e) => setData({ ...data, name: e.target.value })}
        />
        {!data.name && <p>Поле не может быть пустым!</p>}
        <Input
          value={data.surname}
          placeholder={"Фамилия"}
          onChange={(e) => setData({ ...data, surname: e.target.value })}
        />
        {!data.surname && <p>Поле не может быть пустым!</p>}
        <Input
          value={data.fatherName}
          placeholder={"Отчество"}
          onChange={(e) => setData({ ...data, fatherName: e.target.value })}
        />
        <Input
          value={data.alias}
          placeholder={"Псевдоним"}
          onChange={(e) => setData({ ...data, alias: e.target.value })}
        />
        {!data.alias && <p>Поле не может быть пустым!</p>}
        <Input
          value={
            data.birthDate
              ? new Date(data.birthDate).toISOString().substr(0, 10)
              : ""
          }
          placeholder={"День рождения"}
          type={"date"}
          onChange={(e) => setData({ ...data, birthDate: e.target.value })}
        />
        <Button
          value={"Сохранить"}
          onClick={() => mutate()}
          disabled={isDisabled}
        />
      </div>
      <div className={styles.div__person__input}>
        <h3>Платежная информация</h3>
        <div className={styles.items}>
          {paymentData?.map((item, i) => (
            <div className={styles.card} key={i}>
              <p>{item.name}</p>
              <Input
                value={item.number}
                onChange={(e) => {
                  const updatedPaymentData = [...paymentData];
                  updatedPaymentData[i] = {
                    ...updatedPaymentData[i],
                    number: e.target.value,
                  };
                  setPaymentData(updatedPaymentData);
                }}
              />
              <IoMdClose
                onClick={() =>
                  setPaymentData((prevData) =>
                    prevData.filter((_, index) => index !== i),
                  )
                }
              />
            </div>
          ))}
        </div>
        <div className={styles.buttons}>
          <Button
            value={"Добавить кошелек"}
            onClick={() => {
              if (paymentData.length >= 4) return;
              setPaymentData([
                ...paymentData,
                { name: `Кошелек ${paymentData.length + 1}`, number: 0 },
              ]);
            }}
          />
          <Button
            value={"Выплатить"}
            onClick={() => console.log("Выплатить")}
          />
        </div>

        <div className={styles.close} onClick={() => setIsUpdateMode(false)}>
          <IoMdClose />
        </div>
      </div>
    </div>
  );
};

export default PersonalDetailsAdmin;
