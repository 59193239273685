import React from "react";
import PersonalDetails from "components/PersonalDetails/PersonalDetails";
import Sum from "components/Sum/Sum";
import styles from "./MyDetails.module.css";
import Layout from "../../components/layout/Layout";
import Header from "components/header/Header";
const MyDetails = () => {
  return (
    <>
      <Layout>
        <div className={styles.myDetails_main}>
          <Header />
          <Sum />
          <PersonalDetails />
        </div>
      </Layout>
    </>
  );
};

export default MyDetails;
