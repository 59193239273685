import React from "react";
import styles from "./input.module.css";

const Input = ({ value, onChange, type, placeholder, className, readonly}) => {
  return (
    <>
      <input
        value={value}
        onChange={(e) => onChange(e)}
        type={type}
        placeholder={placeholder}
        className={`${styles.input} ${className}`}
				lang="ru"
        readOnly = {readonly}
        maxLength={256}
      />
    </>
  );
};

export default Input;
