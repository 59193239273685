import React, { useEffect, useRef, useState } from "react";
import "./signup.css";

const Calendar = ({ data, setData, emptyField }) => {
  const inputRef = useRef(null);
  const [maxDateString, setMaxDateString] = useState("");

  useEffect(() => {
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 14);
    setMaxDateString(maxDate.toISOString().split('T')[0]);

    if (inputRef.current) {
      inputRef.current.onfocus = () => {
        inputRef.current.type = "date";
        inputRef.current.placeholder = "";
      };
      inputRef.current.onblur = () => {
        if (!inputRef.current.value) {
          inputRef.current.type = "text";
          inputRef.current.placeholder = "Дата рождения*";
        }
      };
    }
  }, []);

  const handleDateChange = (e) => {
    const value = e.target.value;
    setData(value);
  };

  return (
    <input
      ref={inputRef}
      value={data.birthDate}
      type="text"
      min="1925-01-01"
      max={maxDateString}
      maxLength={10}
      placeholder="Дата рождения*"
      className={emptyField === "birthDate" ? "error" : "input"}
      onChange={handleDateChange}
    />
  );
};

export default Calendar;
