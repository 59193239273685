import { memo } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import './Calendar.css'
import { IoClose } from "react-icons/io5";



const DateRangePickerComponent= (props) => {
  const {
    editableDateInputs = false,
    locale,
    onChange,
    ranges,
    close,
    open,
    payment

  } = props;
  const currentYear = new Date().getFullYear();
  const previousYear = 2021;

  return ( 
    <div className={payment ? "calendar-container-payment":"calendar-container"}>
    <div className="scrollable-calendar">
    <div className="header">
        <p>Выберите период</p>
        <IoClose onClick={() => close(!open)}/>
    </div>
    <DateRange
      {...props}
      direction="vertical" // direction of calendar months. can be vertical or horizontal
      editableDateInputs={editableDateInputs} // whether dates can be edited in the Calendar's input fields
      locale={locale}
      onChange={onChange} // startDate/endDate callback function for range changes. fn(changes). changes contains changed ranges with new startDate/endDate properties.
      ranges={ranges} // Defines ranges. array of range object
      color="FF6E1D"
      rangeColors={["#ff6e1d"]}
      scroll={{ enabled: true }} // infinite scroll behaviour configuration. Check out Infinite Scroll section
      showDateDisplay={false} // show/hide selection display row. Uses dateDisplayFormat for formatter
      showMonthAndYearPickers={false} // show select tags for month and year on calendar top, if false it will just display the month and year
      showPreview={true} 
      minDate={new Date(previousYear, 0, 1)}
      maxDate={new Date(currentYear + 1, 11, 31)}
      months={12}
      // visibility of preview
    />
    </div>
    <button onClick={() => close(!open)} className="close_calendar">Продолжить</button>  
    </div>
    
  );
};

export const DateRangePicker = memo(DateRangePickerComponent);