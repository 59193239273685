import { createContext, useEffect, useState } from "react";
import { AdminRouter } from "routes/admin/AdminRouter";
import { DashboardRouter } from "routes/dashboard/DashboardRouter";
import { LoginRouter } from "routes/login/LoginRouter";
import { checkRole } from "utils/checkRole";
import "./main.css";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "components/Modal/Modal";
import { useModalStore } from "providers/ModalStoreProvider";
import { AdminStoreProvider } from "providers/AdminStoreProvider";



export const TokenContext = createContext({
  token: localStorage.getItem("token") || "",
  setToken: (callback = (prev = "") => {}) => {},
});

export const App = () => {
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  const isAdmin = checkRole();
  const { pathname } = useLocation();
  const nav = useNavigate();

  const [modalStore] = useModalStore()
  const message = modalStore?.message
  
  useEffect(() => {
    if (pathname === "/login" && token) {
      if (isAdmin) nav("/admin");
      else {
        nav("/home");
      }
    }
  }, [pathname, nav, isAdmin, token]);
  const routers = {
    "true": <AdminRouter />,
    "false": <DashboardRouter />,
  };
  return (
      <TokenContext.Provider value={{ token, setToken }}>
        <AdminStoreProvider>
              {message ? <Modal content={message} button={modalStore.button} handleClick={modalStore.handleClick}/> : ''}
              <div className="div_main">
                {token ? routers[isAdmin.toString()] : <LoginRouter />}
              </div>
          </AdminStoreProvider> 
      </TokenContext.Provider>
   
  );
};