import React, { useContext } from "react";
import Button from "../Base/Button/Button";
import Input from "../Base/Input/Input";
import { Link } from "react-router-dom";
import { useState } from "react";
import "./login.css";
import { useEffect } from "react";
import { baseUrl} from "../../config";
import Wrong from "./Wrong/Wrong";
import axios from "axios";
import { TokenContext } from "../../App";
import Captcha from "components/Captcha/Captcha";
import { useAdminStore } from "providers/AdminStoreProvider";
import { setIsAdmin } from "store/adminStore/actions";
import { useModalStore } from "providers/ModalStoreProvider";
import { setModal } from "store/modalStore/action";
import { errorCatch } from "utils/errorCatch";
import {IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
export const LogIn = () => {
  const { setToken } = useContext(TokenContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isError, setIsError] = useState(false);
  const [captcha, setCaptcha] = useState(false); 
  const [isAgree, setIsAgree] = useState(false);
  const [adminStore, setAdminStore] = useAdminStore()
  const [, setModalStore] = useModalStore()
  const [isPasswordShow, setIsPasswordShow] = useState(false)



  const handleLoginAdmin = async () => {
    if (captcha && isAgree){try {
      const { data } = await axios.post(`${baseUrl}/admin/login`, {
        email,
        password,
      });
      setToken(data.token);
      localStorage.setItem("role", data.role);
      localStorage.setItem("token", data.token);
    } catch (error) {
      setModalStore(setModal(errorCatch(error)))
    }}
  };

   useEffect(() => {
    email === 'bookvawebsite@gmail.com' ? setAdminStore(setIsAdmin(true)) : setAdminStore(setIsAdmin(false))
  }, [email, setAdminStore])


  const handleLogin = async () => {
   if (captcha && isAgree){
    try {
      const { data } = await axios.post(`${baseUrl}/author/login`, {
        email,
        password,
      });
      setToken(data.token);
      localStorage.setItem("token", data.token);
    } catch (e) {
      setModalStore(setModal(errorCatch(e)))
    }
   }
  };


  const setHandleShow = () => {
    setIsError(false);
  };

  return (
    <>
      {isError ? <Wrong setHandleShow={setHandleShow} /> : null}
      <div className="login__container">
        <div className="login">
          <h1>Авторизация</h1>
          <div>
            <Input
              type={"text"}
              placeholder={"E-mail"}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <label className="password">
            <Input
              type={isPasswordShow ? "text" : "password"}
              placeholder={"Пароль"}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            {isPasswordShow ? <IoEyeOutline onClick={() => setIsPasswordShow(!isPasswordShow)}/> : <IoEyeOffOutline onClick={() => setIsPasswordShow(!isPasswordShow)}/>}
            </label>
            
          </div>
          <div className="radio">
            <input
              type="radio"
              className="custom-input"
              id="customCheck1"
              value={isAgree}
              onChange={() => setIsAgree(true)}
            />
            <label className="custom-label" htmlFor="customCheck1">
              <Link to={'/data-processing'} className="data-processing-link" target="_blank">Согласен на обработку персональных данных</Link>
            </label>
          </div>

          <Captcha onChange={() => setCaptcha(!captcha)} onError={() => setCaptcha(false)} />
          <Button
            disabled={!(captcha && isAgree)}
            value={"Войти"}
            onClick={() => (adminStore.isAdmin ? handleLoginAdmin() : handleLogin())}
          />
          <div className="rout redirectLink">
            <Link to={"/sign-up"}>Регистрация</Link>
            <Link to={"/forgot-password"}>Забыли пароль?</Link>
          </div>
        </div>
      </div>
    </>
  );
};

