import React from "react";

const MonetizationIcon = () => {
  return (
    <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.5 8.66667H7.2C7.43869 8.66667 7.66761 8.57536 7.8364 8.41283C8.00518 8.25029 8.1 8.02985 8.1 7.8C8.1 7.57015 8.00518 7.34971 7.8364 7.18717C7.66761 7.02464 7.43869 6.93333 7.2 6.93333H4.5C4.2613 6.93333 4.03239 7.02464 3.8636 7.18717C3.69482 7.34971 3.6 7.57015 3.6 7.8C3.6 8.02985 3.69482 8.25029 3.8636 8.41283C4.03239 8.57536 4.2613 8.66667 4.5 8.66667ZM15.3 0H2.7C1.98392 0 1.29716 0.273928 0.790812 0.761522C0.284464 1.24912 0 1.91044 0 2.6V10.4C0 11.0896 0.284464 11.7509 0.790812 12.2385C1.29716 12.7261 1.98392 13 2.7 13H15.3C16.0161 13 16.7028 12.7261 17.2092 12.2385C17.7155 11.7509 18 11.0896 18 10.4V2.6C18 1.91044 17.7155 1.24912 17.2092 0.761522C16.7028 0.273928 16.0161 0 15.3 0ZM16.2 10.4C16.2 10.6299 16.1052 10.8503 15.9364 11.0128C15.7676 11.1754 15.5387 11.2667 15.3 11.2667H2.7C2.46131 11.2667 2.23239 11.1754 2.0636 11.0128C1.89482 10.8503 1.8 10.6299 1.8 10.4V5.2H16.2V10.4ZM16.2 3.46667H1.8V2.6C1.8 2.37015 1.89482 2.14971 2.0636 1.98717C2.23239 1.82464 2.46131 1.73333 2.7 1.73333H15.3C15.5387 1.73333 15.7676 1.82464 15.9364 1.98717C16.1052 2.14971 16.2 2.37015 16.2 2.6V3.46667Z" fill="currentColor" />
  </svg>
  );
};

export default MonetizationIcon;
