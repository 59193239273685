import { createContext, useContext, useReducer } from "react";
import { bookReducer, bookStoreInitialState } from "store/bookStore";

const BookstoreContext = createContext([]);

export const BookstoreProvider = ({ children }) => {
  const bookStore = useReducer(bookReducer, bookStoreInitialState);

  return (
    <BookstoreContext.Provider value={bookStore}>
      {children}
    </BookstoreContext.Provider>
  );
};

export const useBookStore = () => useContext(BookstoreContext);
